import { render, staticRenderFns } from "./CouponManagement.vue?vue&type=template&id=dea45372&scoped=true"
import script from "./CouponManagement.vue?vue&type=script&lang=ts"
export * from "./CouponManagement.vue?vue&type=script&lang=ts"
import style0 from "./CouponManagement.vue?vue&type=style&index=0&id=dea45372&prod&lang=less&scoped=true"
import style1 from "./CouponManagement.vue?vue&type=style&index=1&id=dea45372&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dea45372",
  null
  
)

export default component.exports