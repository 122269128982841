
import axios from '../../plugins/axios.js';
import { Provide, Component, Prop, Ref, Mixins } from 'vue-property-decorator';
import CourseClassifyMixins from '@/views/shared/CourseClassifyMixins.vue';
import AddContestModal from './modals/AddContestModal.vue';

@Component({
    components: {
        AddContestModal,
    },
})

export default class PartnerContestManagement extends Mixins(CourseClassifyMixins) {
    @Prop() private partnerCode!: any;
    @Provide() private contests: any = [];
    @Provide() private queriedCondition: { [index: string]: any } = {
        partnerName: '',
        partnerCode: '',
        contestId: '',
        title: '',
        page: 1,
    };
    @Provide() private pageSize: number = 10;
    @Provide() private loading: boolean = false;
    @Provide() private totalContests: number = 0;

    @Ref() private readonly addContestModal;

    private mounted() {
        this.queriedCondition.partnerCode = this.partnerCode;
        this.getContests();
    }
    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.getContests();
    }
    private queryContests() {
        this.queriedCondition.page = 1;
        this.getContests();
    }
    private getContests() {
        this.loading = true;
        axios.get('/api/v1/partner-manage/contests', this.queriedCondition).then(({ contests, total }) => {
            this.contests = contests;
            this.totalContests = total;
            this.loading = false;
        }).catch((error) => {
            this.$message.error(error);
        });
    }
    private openAddContestModal() {
        this.addContestModal.$emit('open');
    }
}
