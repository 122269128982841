import { render, staticRenderFns } from "./PartnerContestManagement.vue?vue&type=template&id=180ff706&scoped=true"
import script from "./PartnerContestManagement.vue?vue&type=script&lang=ts"
export * from "./PartnerContestManagement.vue?vue&type=script&lang=ts"
import style0 from "./PartnerContestManagement.vue?vue&type=style&index=0&id=180ff706&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "180ff706",
  null
  
)

export default component.exports