
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Prop, Ref } from 'vue-property-decorator';

@Component
export default class AddContestModal extends Vue {
    @Prop() private partnerCode!: any;
    @Provide() private showDialog: boolean = false;
    @Provide() private contest: { [index: string]: any } = {
        partnerCode: '',
        contestId: '',
        title: '',
        startTime: '',
        endTime: '',
    };
    @Provide() private rules: { [index: string]: object[] } = {
        partnerCode: [
            { required: true, message: '请填写机构代码', trigger: 'blur' },
        ],
        contestId: [
            { required: true, message: '请填写比赛 ID', trigger: 'blur' },
            { validator: this.validateContestId, trigger: 'blur' },
        ],
    };
    @Provide() private isLoading: boolean = false;
    @Ref() private readonly partnerForm;

    private created() {
        this.$on('open', () => {
            this.showDialog = true;
            this.contest.partnerCode = this.partnerCode;
        });
    }

    private validateContestId(rule: any, value: any, callback: any) {
        this.isLoading = true;
        axios.get('/api/v1/partner-manage/contest', {
            contestId: this.contest.contestId,
        }).then(({ title, startTime, endTime }) => {
            this.contest.title = title;
            this.contest.startTime = startTime;
            this.contest.endTime = endTime;
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.isLoading = false;
        });
        return callback();
    }

    private clearValidate() {
        this.partnerForm.clearValidate();
    }

    private submit() {
        this.partnerForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            this.isLoading = true;
            axios.post('/api/v1/partner-manage/create-contest', this.contest).then(() => {
                this.$message({
                    type: 'success',
                    message: '添加比赛成功',
                });
                this.clearForm();

                this.showDialog = false;
                this.$emit('refresh-contests');
            }).catch((error) => {
                this.$message.error(error);
            }).then(() => {
                this.isLoading = false;
            });
        });
    }

    private clearForm() {
        this.contest = {
            partnerCode: '',
            contestId: '',
            title: '',
            startTime: '',
            endTime: '',
        };
    }
}
