
import _ from 'lodash';
import axios from '@/plugins/axios.js';
import { Vue, Provide, Component, Prop, Ref, Watch } from 'vue-property-decorator';
@Component
export default class UpdateCouponTemplate extends Vue {
    @Prop() private courseClassifyOptions;

    @Provide() private couponTemplate: { [index: string]: any } = {
        duration: 0,
    };
    @Provide() private loading: boolean = false;
    @Provide() private showDialog: boolean = false;
    @Provide() private submitting: boolean = false;
    @Provide() private courseId: string = '';
    @Provide() private courses: any[] = [];

    @Provide() private couponConstraints: any[] = [
        {
            title: '不限制',
            value: 'noConstraint',
        },
        {
            title: '限制课程 ID',
            value: 'courseConstraint',
        },
        {
            title: '限制课程类型',
            value: 'categoryConstraint',
        },
    ];
    @Provide() private rules: { [index: string]: object[] } = {
        title: [
            { required: true, message: '请填写优惠券标题', trigger: 'blur' },
        ],
        discount: [
            { required: true, message: '请填写优惠券金额', trigger: 'blur' },
        ],
        couponConstraint: [
            { required: true, message: '请选择优惠券限制条件', trigger: 'blur' },
        ],
        isUnrelatable: [
            { required: true, message: '请选择优惠券叠加限制', trigger: 'blur' },
        ],
        isIndependent: [
            { required: true, message: '请选择优惠券被叠加限制', trigger: 'blur' },
        ],
        isSingleDesignated: [
            { required: true, message: '请选择优惠券每课限一张限制', trigger: 'blur' },
        ],
        courseIds: [
            { validator: this.validateCourseIds, trigger: 'blur' },
        ],
        duration: [
            { validator: this.validateDuration, trigger: 'blur' },
        ],
    };
    @Ref() private readonly couponTemplateForm;

    private get isAddingCouponTemplate() {
        return this.couponTemplate.id === 0;
    }

    private get title() {
        if (this.isAddingCouponTemplate) {
            return '创建优惠券模版';
        }
        return '编辑优惠券模版';
    }

    public created() {
        this.$on('open', (couponTemplate) => {
            this.showDialog = true;
            const secondsOfOneDay = 86400;
            const courseIds = _.cloneDeep(couponTemplate.courseIds);
            this.couponTemplate = _.cloneDeep(couponTemplate);
            this.couponTemplate.duration = this.couponTemplate.duration / secondsOfOneDay;
            this.couponTemplate.courseIds = [];
            courseIds.forEach((courseId) => {
                this.getCourseTitle(parseInt(courseId, 0));
            });
        });
    }

    private validateCourseIds(rule: any, value: any, callback: any) {
        if (this.couponTemplate.couponConstraint === 'courseConstraint' && value.length === 0) {
            return callback(new Error('请填写限制课程 ID'));
        }
        return callback();
    }

    private validateDuration(rule: any, value: any, callback: any) {
        if (!value) {
            return callback(new Error('请填写优惠券有效时长'));
        }
        return callback();
    }

    private handleChange(value) {
        this.couponTemplate.classifies = value;
    }

    private couponLimitChanged(value) {
        this.couponTemplate.classifies = [];
        this.couponTemplate.courseIds = [];
        this.courses = [];
        this.courseId = '';
    }

    private beforeAddingCourseId(courseId) {
        if (!courseId || this.couponTemplate.courseIds.includes(courseId)) {
            return;
        }
        if (!(/^\+?(0|[1-9]\d*)$/).test(courseId)) {
            return this.$message.error('课号必须为纯数字');
        }
        this.getCourseTitle(courseId);
    }

    private removeCourse(courseId) {
        this.courses = this.courses.filter((course) => course.courseId !== courseId);
        this.couponTemplate.courseIds = this.couponTemplate.courseIds.filter((courseIdInInvitation) => courseIdInInvitation !== courseId);
    }

    private getCourseTitle(newCourseId) {
        this.loading = true;
        axios.get('/api/v1/sales/get-course', {
            courseId: newCourseId,
        }).then(({ title }) => {
            this.courses.push({
                courseId: newCourseId,
                title,
            });
            this.courseId = '';
            this.couponTemplate.courseIds.push(newCourseId);
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.loading = false;
        });
    }

    private createOrUpdateCouponTemplate() {
        this.couponTemplateForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            if (this.couponTemplate.couponConstraint === 'categoryConstraint' && this.couponTemplate.classifies.length === 0) {
                return this.$message.error('请选择限制课程类型');
            }
            const url = this.isAddingCouponTemplate ? '/api/v1/coupon-template/create' : '/api/v1/coupon-template/update';
            this.couponTemplate.couponTemplateId = this.couponTemplate.id;
            this.submitting = true;
            axios.post(url, this.couponTemplate).then(() => {
                this.$message({
                    type: 'success',
                    message: '更新优惠券模版成功',
                });
                this.showDialog = false;
                this.submitting = false;
                this.$emit('refresh-coupon-templates');
                this.clearForm();
            }).catch((error) => {
                this.submitting = false;
                this.$message.error(error);
            });
        });
    }

    private clearForm() {
        this.couponTemplate = {
            duration: 0,
        };
        this.courses = [];
        this.courseId = '';
    }
}
