
import axios from '@/plugins/axios.js';
import { Vue, Provide, Component, Ref } from 'vue-property-decorator';
@Component
export default class AddCouponMixins extends Vue {
    @Provide() public coupon: { [index: string]: any } = {
        title: '',
        discount: null,
        isIndependent: 1,
        isUnrelatable: 1,
        isSingleDesignated: 0,
        phone: '',
        reason: '',
        couponConstraint: '',
        courseIds: [],
        classifyIds: [],
        beginAt: '',
        endAt: '',
    };
    @Provide() public showDialog: boolean = false;
    @Provide() public submitting: boolean = false;
    @Provide() public selectedTemplateIndex: any = null;
    @Provide() public courseId: string = '';
    @Provide() public courses: any[] = [];
    @Ref() public readonly couponForm;
    public created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }
    public clearValidate() {
        this.couponForm.clearValidate();
    }
    public addCoupon() {
        this.couponForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            const url = '/api/v1/sales/create-coupon';
            this.submitting = true;
            axios.post(url, this.coupon).then(() => {
                this.$message({
                    type: 'success',
                    message: '添加优惠券成功',
                });
                this.clearForm();
                this.showDialog = false;
                this.submitting = false;
                this.$emit('refreshCoupons');
            }).catch((error) => {
                this.submitting = false;
                this.$message.error(error);
            });
        });
    }
    public clearForm() {
        this.coupon = {
            title: '',
            discount: null,
            isIndependent: 1,
            isUnrelatable: 1,
            isSingleDesignated: 0,
            couponConstraint: '',
            courseIds: [],
            classifyIds: [],
            beginAt: '',
            endAt: '',
            phone: '',
            reason: '',
        };
        this.courses = [];
        this.courseId = '';
        this.selectedTemplateIndex = null;
    }
}
