
import Vue from 'vue';
import moment from 'moment';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Prop, Watch, Ref } from 'vue-property-decorator';
@Component
export default class UpdateCourse extends Vue {
    @Prop() private selectedCourse;
    @Provide() private showDialog: boolean = false;
    @Provide() private course: any = {
        courseDate: [],
    };
    @Provide() private rules: { [index: string]: object[] } = {
        courseDate: [
            { required: true, message: '请选择招生时间', trigger: 'blur' },
        ],
    };
    @Ref() private readonly courseForm;
    private created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }

    @Watch('selectedCourse', { deep: true })
    private updateCourseDate() {
        if (!this.selectedCourse.startDate && !this.selectedCourse.endDate) {
            this.course.courseDate = [];
        } else {
            this.course.courseDate = [this.selectedCourse.startDate, this.selectedCourse.endDate];
        }
    }
    private clearValidate() {
        this.courseForm.clearValidate();
    }
    private updateDatePicker() {
        this.$forceUpdate();
    }
    private confirm() {
        this.courseForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            if (moment(this.course.courseDate[1]).diff(moment(this.course.courseDate[0]), 'days') > 120) {
                return this.$message.error('时间范围不能超过 120 天');
            }
            axios.post('/api/v1/partner-manage/update-course', {
                courseId: this.selectedCourse.courseId,
                startDate: this.course.courseDate[0],
                endDate: this.course.courseDate[1],
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '保存成功',
                });
                this.showDialog = false;
                this.$emit('refresh-courses');
            }).catch((error) => {
                this.$message.error(error);
            });
        });
    }
}
