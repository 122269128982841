import { render, staticRenderFns } from "./StudentCourses.vue?vue&type=template&id=a3574708&scoped=true"
import script from "./StudentCourses.vue?vue&type=script&lang=ts"
export * from "./StudentCourses.vue?vue&type=script&lang=ts"
import style0 from "./StudentCourses.vue?vue&type=style&index=0&id=a3574708&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3574708",
  null
  
)

export default component.exports