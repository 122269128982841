
import axios from '../../plugins/axios.js';
import { Provide, Component, Vue } from 'vue-property-decorator';

@Component({})
export default class RecommenderUserManagement extends Vue {
    @Provide() private users: any = [];
    @Provide() private loading: boolean = false;
    @Provide() private totalUsers: number = 0;
    @Provide() private pageSize: number = 10;
    @Provide() private queriedCondition: { [index: string]: any } = {
        userAccount: '',
        statuses: [],
        sortProp: 'createdAt',
        sortOrder: 'descending',
        page: 1,
    };
    @Provide() private statusOptions: any = [
        {
            text: '已绑定，待成单',
            value: 'ongoing',
        },
        {
            text: '已成单',
            value: 'finished',
        },
        {
            text: '绑定失败',
            value: 'failed',
        },
        {
            text: '绑定过期',
            value: 'expired',
        },
    ];

    private get phone() {
        return this.$route.query.phone;
    }

    private mounted() {
        this.getRecommendedUsers();
    }

    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.getRecommendedUsers();
    }

    private sortChange({ prop, order }) {
        this.queriedCondition.sortProp = prop ? prop : 'createdAt';
        this.queriedCondition.sortOrder = order ? order : 'descending';
        this.queriedCondition.page = 1;
        this.getRecommendedUsers();
    }

    private changeFilter(filter) {
        if (filter.hasOwnProperty('statuses')) {
            this.queriedCondition.statuses = filter.statuses;
        }
        this.queriedCondition.page = 1;
        this.getRecommendedUsers();
    }

    private queryRecommendedUsers() {
        this.queriedCondition.page = 1;
        this.getRecommendedUsers();
    }

    private getRecommendedUsers() {
        this.loading = true;
        axios.get('/api/v1/recommender-management/recommended-users', {
            phone: this.phone,
            ...this.queriedCondition,
        }).then(({ users, total }) => {
            this.loading = false;
            this.users = users;
            this.totalUsers = total;
        }).catch((error) => {
            this.loading = false;
            this.$message.error(error);
        });
    }

    private formattedStatus(status) {
        switch (status) {
            case 'ongoing':
                return '已绑定，待成单';
            case 'expired':
                return '绑定过期';
            case 'finished':
                return '已成单';
            case 'failed':
                return '绑定失败';
            default:
                return '已绑定，待成单';
        }
    }
}
