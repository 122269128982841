
import axios from '../plugins/axios.js';
import eventBus from '../plugins/eventBus.js';
import { Provide, Component, Watch, Vue } from 'vue-property-decorator';

@Component
export default class BreadCrumb extends Vue {
    @Provide() private breadcrumbItemsName: { [index: string]: string } = {
        'education-system': '教务系统',
        'instructors': '教师团队',
        'courses-lectures-management': '课表管理',
        'parent-notification': '家长消息管理',
        'crm': 'CRM 系统',
        'customer-management': '客户信息录入',
        'sales-scheduling': '销售/学管管理',
        'sales-scheduling-statistics': '销售线索分配统计',
        'express-manage': '快递管理',
        'express-input': '快递信息录入',
        'advisor-system': '班主任系统',
        'class-manage': '班级管理',
        'class-student-manage': '班级管理',
        'administration': '管理功能',
        'authority-manage': '权限管理',
        'switch-course-record': '转班记录',
        'other': '其他功能',
        'clue-pool-customers': '客户列表',
        'customer-input-statistics': '客户信息统计',
        'sem': 'SEM 系统',
        'launch-clues-statistic': '投放成单统计',
        'financial-manage': '财务管理',
        'invoice-manage': '发票管理',
        'marketing-manage': '营销管理',
        'ad-setting': '广告管理',
        'multimedia-upload': '资料上传',
        'business': '对公业务系统',
        'partner-operation-approval': '机构操作审批',
        'partner-data-summary': '机构数据汇总',
        'partner-manage': '机构管理',
        'partner-course-management': '机构课程管理',
        'partner-contest-management': '机构比赛管理',
        'crossdomain-allowlist-management': '跨域白名单管理',
        'pay-invitation': '支付邀请',
        'coupon-management': '优惠券管理',
        'coupon-template-management': '优惠券模版管理',
        'tutoring-manage': '答疑管理',
        'transaction-manage': '订单管理',
        'referral-management': '转介绍管理',
        'drop-course-record': '退班记录',
        'problem-set-evaluation-manage': '真题测评管理',
        'marketing-campaign-management': '营销活动管理',
        'voucher-management': '权益管理',
        'partner-transaction': '账单管理',
        'partner-billing': '机构计费',
        'lecture-preparation-manage': '备课管理',
        'advisor-students-management': '学生管理',
        'courses-management': '开班管理',
        'course-lectures-management': '排班',
        'course-authority-setting': '课程权限',
        'transaction-refund-management': '退费管理',
        'potential-customer': '潜在客户',
        'recommender-management': '推荐官管理',
        'recommender-user-management': '用户管理',
    };

    @Provide() private breadcrumbItems: object[] = [];

    @Provide() private courseTitle = '';

    @Provide() private roleTitle: string = '';
    private created() {
        this.getBreads();
    }

    @Watch('$route')
    private getBreads() {
        this.breadcrumbItems = [];
        for (const routeInfo of this.$route.matched) {
            if (typeof routeInfo.name === 'string' && routeInfo.name !== 'home') {
                this.courseTitle = '';
                switch (routeInfo.name) {
                    case 'course-lectures-management':
                        this.breadcrumbItems.push({title: this.breadcrumbItemsName['courses-management'], path: 'courses-management'});
                        this.getCourseTitle(String(this.$route.query.courseId), '/courses-management');
                        this.breadcrumbItems.push({title: this.breadcrumbItemsName[routeInfo.name] + '：course-title', path: 'course-lectures-management'});
                        break;
                    case 'class-student-manage':
                        this.breadcrumbItems.push({title: this.breadcrumbItemsName['class-manage'], path: 'class-manage'});
                        this.getCourseTitle(String(this.$route.query.courseId), '/class-manage');
                        this.breadcrumbItems.push({title: 'course-title', path: ''});
                        break;
                    case 'create-invoice':
                        this.breadcrumbItems.push({title: this.breadcrumbItemsName['invoice-manage'], path: 'invoice-manage'});
                        this.breadcrumbItems.push({title: String(this.$route.query.swiftid), path: ''});
                        break;
                    case 'partner-transaction':
                        this.breadcrumbItems.push({title: this.breadcrumbItemsName['partner-manage'], path: 'partner-manage'});
                        this.breadcrumbItems.push({title: this.$route.query.partnerCode + this.breadcrumbItemsName['partner-transaction'], path: ''});
                        break;
                    case 'recommender-user-management':
                        this.breadcrumbItems.push({title: this.breadcrumbItemsName['recommender-management'], path: 'recommender-management'});
                        this.breadcrumbItems.push({title: this.$route.query.phone + this.breadcrumbItemsName['recommender-user-management'], path: ''});
                        break;
                    default:
                        this.breadcrumbItems.push({title: this.breadcrumbItemsName[routeInfo.name], path: routeInfo.name});
                        break;
                }
            }
        }
    }

    private processBread(breadcrumbItem: string) {
        breadcrumbItem =  breadcrumbItem.replace(/course-title/g, this.courseTitle);
        breadcrumbItem =  breadcrumbItem.replace(/role-title/g, this.roleTitle);
        return breadcrumbItem;
    }

    private getCourseTitle(courseId: string, redirectUrl) {
        axios.get('/api/v1/courses-management/course-title', {
            course_id: courseId,
        }).then(({ title }) => {
            this.courseTitle = `#${courseId} ${title}`;
            eventBus.$emit('setCourseTitle', title);
        }).catch((error) => {
            this.$message.error(error);
            this.$router.push(redirectUrl);
        });
    }
}
