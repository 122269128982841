
import Vue from 'vue';
import moment from 'moment';
import axios from '../../../plugins/axios.js';
import UpdateLecture from '../modals/UpdateLecture.vue';
import { Provide, Component, Prop, Ref } from 'vue-property-decorator';

@Component({
    components: {
        UpdateLecture,
    },
})
export default class LiveCourseLectures extends Vue {
    @Prop() private courseId!: number;
    @Provide() private course: { [index: string]: number } = {};
    @Provide() private instructors: any = {};
    @Provide() private chapters: any = {};
    @Provide() private lectures: any = [];
    @Provide() private lecture: { [index: string]: any } = {
        time: '',
        minutes: 120,
        instructor: '',
        dates: [],
        videoId: '',
    };
    @Provide() private lectureScheduleForm: { [index: string]: any } = {
        time: '',
    };
    @Provide() private rules: { [index: string]: object[] } = {
        time: [
            { required: true, message: '请选择上课时间' },
        ],
        instructor: [
            { required: true, message: '请选择授课教师' },
        ],
        dates: [
            { required: true, message: '请选择上课日期' },
        ],
    };
    @Provide() private selectedLecture: any = {};
    @Provide() private loading: boolean = true;
    @Provide() private hasModified: boolean = false;
    @Ref() private readonly lectureForm;
    @Ref() private readonly updateLectureModal;

    private mounted() {
        window.addEventListener('beforeunload', (e) => {
            if (this.hasModified) {
                e.returnValue = '系统可能不会保存你所做的修改';
            }
            return '系统可能不会保存你所做的修改';
        }, false);
        this.getLectures();
    }

    private formatChapterTitle(chapter, index) {
        return `[第 ${index + 1} 部分] ${chapter.title}`;
    }

    private getLectures() {
        this.loading = true;
        axios.get('/api/v1/course-lectures-management/lectures', {
            courseId: this.courseId,
        }).then((response: any) => {
            this.lectures = response.lectures;
            this.course = response.course;
            this.chapters = response.chapters;
            this.instructors = response.instructors;
            this.lectureScheduleForm.time = this.course.arrange;
            for (const index in this.instructors) {
                if (this.instructors[index].remarks) {
                    this.instructors[index].fullName = `${this.instructors[index].name}(${this.instructors[index].remarks})`;
                } else {
                    this.instructors[index].fullName = this.instructors[index].name;
                }
            }
            this.loading = false;
        }).catch((error) => {
            this.loading = false;
            this.$message.error(error);
        });
    }

    private addLecture() {
        this.lectureForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            for (const lecture of this.lecture.dates) {
                this.lectures.push({
                    id: 0,
                    description: '',
                    course_date: lecture,
                    start_time: this.lecture.time,
                    minutes: this.lecture.minutes,
                    instructor_id: this.lecture.instructor,
                    videoId: '',
                    chapterLessonId: 0,
                });
            }
            this.$message({
                type: 'success',
                message: '添加讲次安排成功',
            });
            this.hasModified = true;
            this.$nextTick(() => {
                this.sortLecturesByDate();
            });
        });
    }
    private handleChapterSelected(chapterId) {
        let targetLecture = this.lectures.find((lecture) => lecture.chapter_id === chapterId);
        if (targetLecture) {
            if (targetLecture.description) {
                return;
            }
            let targetChapter = this.chapters.find((chapter) => chapter.chapter_id === chapterId);
            targetLecture.description = targetChapter.title;
        }
    }
    private openEditModal(selectedLecture) {
        this.selectedLecture = selectedLecture;
        this.updateLectureModal.$emit('open', this.selectedLecture);
    }
    private updateLecture(lecture) {
        this.selectedLecture.course_date = lecture.date;
        this.selectedLecture.start_time = lecture.time;
        this.selectedLecture.minutes = lecture.minutes;
        this.selectedLecture.videoId = lecture.videoId;
        this.hasModified = true;
        this.$nextTick(() => {
            this.sortLecturesByDate();
        });
    }

    private sortLecturesByDate() {
        this.lectures = this.lectures.sort((previous, next) => {
            const previousLectureAt = moment(`${previous.course_date} ${previous.start_time}`);
            const nextLectureAt = moment(`${next.course_date} ${next.start_time}`);
            return previousLectureAt.isAfter(nextLectureAt) ? 1 : -1;
        });
    }

    private deleteLecture(index: number) {
        this.$confirm('该讲次将会被删除且无法还原，是否继续？', '删除讲次', {
            confirmButtonText: '确定',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            this.lectures.splice(index, 1);
            this.$message({
                type: 'success',
                message: '已成功删除讲次',
            });
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消移除',
            });
        });
    }

    private showPublishLectureConfirmDialog() {
        if (this.lectures.length > this.course.course_period || this.lectures.length === 0) {
            return this.$message.error(`该班级共 ${this.course.course_period} 次课，已排 ${this.lectures.length} 次课，未完成排班呢，无法提交，请检查`);
        }
        for (let i = 0; i < this.lectures.length; i++) {
            const current = this.lectures[i];
            if (!current.start_time || !current.minutes || !current.course_date || !current.instructor_id) {
                return this.$message.error('存在内容填写不完整的讲次，请检查');
            }
            for (let j = i + 1; j < this.lectures.length; j++) {
                const another = this.lectures[j];
                if (current.start_time === another.start_time && current.course_date === another.course_date) {
                    return this.$message.error('存在两个讲次上课日期与时间冲突，请检查');
                }
                if (current.chapter_id && another.chapter_id && current.chapter_id === another.chapter_id) {
                    return this.$message.error('存在两个讲次上课内容冲突，请检查');
                }
            }
        }
        if (this.lectureScheduleForm.time === '') {
            return this.$message.error('请填写时间安排');
        }
        this.$confirm('是否同步向学生发布本班级所有学习内容', '发布班级', {
            distinguishCancelAndClose: true,
            confirmButtonText: '发布',
            confirmButtonClass: 'el-button',
            cancelButtonText: '仅排课',
        }).then(() => {
            this.submitForm(true);
        }).catch((action) => {
            if (action === 'cancel') {
                this.submitForm(false);
            }
        });
    }

    private submitForm(isPublishChapters) {
        this.loading = true;
        axios.post('/api/v1/course-lectures-management/update-lectures', {
            lectures: JSON.stringify(this.lectures),
            courseId: this.courseId,
            lectureSchedule: this.lectureScheduleForm.time,
            isPublishChapters,
        }).then((response: any) => {
            this.$message({
                type: 'success',
                message: response,
            });
            this.loading = false;
            this.hasModified = false;
        }).catch((error) => {
            this.loading = false;
            this.$message.error(error);
        });
    }
}
