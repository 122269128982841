
import axios from '../../plugins/axios.js';
import { Vue, Provide, Component, Ref } from 'vue-property-decorator';
import AddRecommenderModal from './modals/AddRecommenderModal.vue';

@Component({
    components: {
        AddRecommenderModal,
    },
})

export default class RecommenderManagement extends Vue {
    @Provide() private recommenders: any = [];
    @Provide() private queriedCondition: { [index: string]: any } = {
        phone: '',
        page: 1,
    };
    @Provide() private pageSize: number = 10;
    @Provide() private loading: boolean = false;
    @Provide() private total: number = 0;

    @Ref() private readonly addRecommenderModal;

    private mounted() {
        this.getRecommenders();
    }
    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.getRecommenders();
    }
    private queryRecommenders() {
        this.queriedCondition.page = 1;
        this.getRecommenders();
    }
    private getRecommenders() {
        this.loading = true;
        axios.get('/api/v1/recommender-management/recommenders', this.queriedCondition).then(({ recommenders, total }) => {
            this.recommenders = recommenders;
            this.total = total;
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.loading = false;
        });
    }
    private openAddRecommenderModal() {
        this.addRecommenderModal.$emit('open');
    }
    private openConfirmDeleteModal(recommender: any) {
        this.$confirm('确定删除该推荐官吗？', '删除推荐官', {
            confirmButtonText: '确认',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            this.deleteRecommender(recommender);
        });
    }
    private deleteRecommender(recommender: any) {
        this.loading = true;
        axios.post(`/api/v1/recommender-management/delete-recommender`, {
            phone: recommender.phone,
        }).then(() => {
            this.$message.success('删除推荐官成功');
            this.getRecommenders();
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.loading = false;
        });
    }
    private toRecommenderUserManagement(phone: string) {
        this.$router.push(`/recommender-user-management?phone=${phone}`);
    }
}
