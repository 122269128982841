
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Ref } from 'vue-property-decorator';

@Component
export default class AddRecommenderModal extends Vue {
    @Provide() private showDialog: boolean = false;
    @Provide() private recommender: { [index: string]: any } = {
        phone: '',
    };
    @Provide() private rules: { [index: string]: object[] } = {
        phone: [
            { required: true, message: '请填写手机号', trigger: 'blur' },
        ],
    };
    @Provide() private isLoading: boolean = false;
    @Ref() private readonly recommenderForm;

    private created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }

    private clearValidate() {
        this.recommenderForm.clearValidate();
    }

    private submit() {
        this.recommenderForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            this.isLoading = true;
            axios.post('/api/v1/recommender-management/create-recommender', this.recommender).then(() => {
                this.$message({
                    type: 'success',
                    message: '添加推荐官成功',
                });
                this.clearForm();

                this.showDialog = false;
                this.$emit('refresh-recommenders');
            }).catch((error) => {
                this.$message.error(error);
            }).then(() => {
                this.isLoading = false;
            });
        });
    }

    private clearForm() {
        this.recommender = {
            phone: '',
        };
    }
}
